import { memo, useEffect } from "react"
import { BottomSheet as SpringBottomSheet, BottomSheetProps as SpringBottomSheetProps } from "react-spring-bottom-sheet"
import tw from "twin.macro"

import { BOTTOM_SHEET } from "@const/z-index"
import { css, Global } from "@emotion/react"

const globalCss = css`
    :root {
        --rsbs-backdrop-bg: rgba(0, 0, 0, 0.25);
        --rsbs-max-w: auto;
        --rsbs-ml: env(safe-area-inset-left);
        --rsbs-mr: env(safe-area-inset-right);
        --rsbs-overlay-rounded: 16px;
        @media (min-width: 600px) {
            :root {
                --rsbs-max-h: 70%;
                --rsbs-ml: auto;
                --rsbs-mr: auto;
            }
        }
    }

    [data-rsbs-root] {
        ${tw`reku-new`}
    }

    [data-rsbs-overlay] {
        border-top-left-radius: var(--rsbs-overlay-rounded);
        border-top-right-radius: var(--rsbs-overlay-rounded);
        display: flex;
        flex-direction: column;
        height: var(--rsbs-overlay-h);
        transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
        will-change: height;

        ${tw`bg-background dark:bg-dark-background`}
    }
    [data-rsbs-overlay]:focus {
        outline: none;
    }
    [data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
        box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);
    }
    [data-rsbs-overlay],
    [data-rsbs-root]:after {
        max-width: var(--rsbs-max-w);
        margin-left: var(--rsbs-ml);
        margin-right: var(--rsbs-mr);
    }
    [data-rsbs-overlay],
    [data-rsbs-backdrop],
    [data-rsbs-root]:after {
        z-index: ${BOTTOM_SHEET};
        overscroll-behavior: none;
        touch-action: none;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        transition: opacity 0.3s;
    }
    [data-rsbs-backdrop] {
        top: -60px;
        bottom: -60px;
        background-color: var(--rsbs-backdrop-bg);
        will-change: opacity;
        cursor: pointer;
        opacity: 1;
    }
    [data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
        cursor: ns-resize;
    }

    [data-rsbs-root]:after {
        content: "";
        pointer-events: none;
        height: 1px;
        transform-origin: bottom;
        transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
        will-change: transform;
        ${tw`bg-background dark:bg-dark-background`}
    }
    [data-rsbs-footer],
    [data-rsbs-header] {
        flex-shrink: 0;
        cursor: ns-resize;
        padding: 16px;
    }
    [data-rsbs-header] {
        text-align: center;
        user-select: none;
        z-index: 1;
        padding-top: calc(20px + env(safe-area-inset-top));
        padding-bottom: 10px;
    }
    [data-rsbs-header]:before {
        position: absolute;
        content: "";
        display: block;
        width: 100px;
        height: 4px;
        top: calc(8px + env(safe-area-inset-top));
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2px;
        background-color: var(--colors-borderColor);
    }
    @media (min-resolution: 2dppx) {
        [data-rsbs-header]:before {
            transform: translateX(-50%) scaleY(0.75);
        }
    }
    [data-rsbs-has-header="false"] [data-rsbs-header] {
        box-shadow: none;
        padding-top: calc(12px + env(safe-area-inset-top));
    }
    [data-rsbs-scroll] {
        flex-shrink: 1;
        flex-grow: 1;
        -webkit-tap-highlight-color: revert;
        -webkit-touch-callout: revert;
        -webkit-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;
        overflow: auto;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
        ::-webkit-scrollbar {
            width: 4px;
        }
        ::-webkit-scrollbar-thumb {
            ${tw`bg-[#D9D9D9]  rounded`}
        }
    }
    [data-rsbs-scroll]:focus {
        outline: none;
    }
    [data-rsbs-has-footer="false"] [data-rsbs-content] {
        padding-bottom: env(safe-area-inset-bottom);
    }
    [data-rsbs-content] {
        overflow: hidden;
    }
    [data-rsbs-footer] {
        overflow: hidden;
        z-index: 1;
        padding-bottom: calc(16px + env(safe-area-inset-bottom));
    }

    [data-rsbs-is-dismissable="true"] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable="true"] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable="true"] [data-rsbs-footer] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-footer] > *,
    [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-footer] > * {
        opacity: 1;
        opacity: var(--rsbs-content-opacity, 1);
    }

    [data-rsbs-is-dismissable="true"] [data-rsbs-backdrop],
    [data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-backdrop],
    [data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-backdrop] {
        opacity: 1;
        opacity: var(--rsbs-backdrop-opacity, 1);
    }

    [data-rsbs-state="closed"],
    [data-rsbs-state="closing"] {
        pointer-events: none;
    }
`

interface BottomSheetProps extends React.PropsWithChildren<SpringBottomSheetProps> {
    className?: string
    dialogClassName?: string
}

/**
 *
 * @example
 *
 * import BottomSheet from '@components/v2/BottomSheet'
 *
 * <BottomSheet isOpen={isOpen} onDismiss={onDismiss}>
 *    <div>Content</div>
 * </BottomSheet>
 *
 *
 */
const BottomSheet: React.FC<BottomSheetProps> = ({
    open,
    className,
    dialogClassName,
    children,
    ...props
}: BottomSheetProps) => {
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                const bottomSheetDialogEl = document.querySelector('[role="dialog"]')

                if (bottomSheetDialogEl && dialogClassName) {
                    bottomSheetDialogEl.classList.add(dialogClassName)
                }
            }, 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <>
            <Global styles={globalCss} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <SpringBottomSheet open={open} data-testid='bottomsheet' initialFocusRef={false} {...props}>
                {children}
            </SpringBottomSheet>
        </>
    )
}

BottomSheet.defaultProps = {
    className: undefined,
    dialogClassName: undefined
}

export default memo(BottomSheet)
